<script> 
export default {
  name: "notify", 
    data() {
        return{   
            notification: this.$store.state.notification.payload
        }
    },
    methods: { 
        Notify: function(){ 
            let header;
            let timer = 5000;
            switch (this.notification.status) {

                case 'success':
                    header =  "Operation successful!";
                    break;  
            
                case 'error':
                    header =  "Error occured!";
                    break;
            
                case 'warning':
                    header =  "Attention please!";
                    break;
            
                case 'info':
                    header =  "Notification!"; 

                    break;
            
                default:
                    break;
            } 
            this.$toast.open({
                message: `<span class="text-white"><span class='fw-bolder fs-6 d-block'>${header}</span> ${this.notification.msg}</span>`,
                type: this.notification.status,
                duration: timer,
                dismissible: true,
                position: "bottom-right",
                pauseOnHover: true
            })

        },
    },
    mounted() {
        this.Notify(); 
    } 
};
</script>