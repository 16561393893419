import CryptoJS from "crypto-js";
import { IsEmpty } from "@/utils";

export const Encrypt = (str) =>
{
    if(!IsEmpty(str))
    {
        let encrypt = CryptoJS.AES.encrypt(JSON.stringify(str), 'erpSystem').toString();
        return encrypt.replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a3S4h').replace(/=/g,'e1Q2u3A4l');
    }
    
    return "";
}

export const Decrypt = (str) =>
{ 
    if(!IsEmpty(str))
    {
        let decrypt = str.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
        return JSON.parse(CryptoJS.AES.decrypt(decrypt, 'erpSystem').toString(CryptoJS.enc.Utf8));
    }
    
    return "";
}

export const ParseAuth = (auth) => Decrypt(auth);

export const SESSION_DURATION = 10*60000 //expires in 3 minutes;

export const CLIENT_OTP_DURATION = () => { 
    let date = new Date();
    let x = new Date(date.getTime() + SESSION_DURATION); 
    return x.getTime();
}

// export const PARSE_AUTH = (auth) =>
// {  
//     let payload = Decrypt(auth);
//     switch (typ) {
//         case 'user':
//         return JSON.parse(payload).user;  
			
//         case 'header':
//         return JSON.parse(payload).headers; 
    
//         default:
//         return null;
//     }   
// }