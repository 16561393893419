import { GetUrl } from "@/router/get-url.js";
export const route = [ 
  {
    path: GetUrl('login').url,
    name: GetUrl("login").name, 
    meta: {title: 'Login', requires_auth: false},
    component: () => import(/* webpackChunkName: "login" */ "@/views/auth/login.vue"),
  },
  {
    path: GetUrl("orders").url,
    name: GetUrl("orders").name, 
    meta: { title: 'All orders', requires_auth: true },
    component: () => import(/* webpackChunkName: "orders" */ "@/views/orders/index.vue"),
  },
  {
    path: GetUrl("sales-and-Transactions").url,
    name: GetUrl("sales-and-Transactions").name, 
    meta: { title: 'Sales & transactions', requires_auth: true },
    component: () => import(/* webpackChunkName: "sales-and-Transactions" */ "@/views/orders/sales-and-Transactions.vue"),
  },
  {
    path: GetUrl('products').url,
    name: GetUrl('products').name,
    meta: { title: 'All products', requires_auth: true },
    component: () => import(/* webpackChunkName: "products" */ "@/views/products/index.vue"),
  },
  {
    path: GetUrl('raw-materials').url,
    name: GetUrl("raw-materials").name, 
    meta: {title: 'Raw materials', requires_auth: false},
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/raw_materials/index.vue"),
  },
  {
    path: GetUrl('activities').url,
    name: GetUrl("activities").name,
    meta: {title: 'activities', requires_auth: true},
    component: () => import(/* webpackChunkName: "all-activities" */ "@/views/user/activities.vue"),
  },
  {
    path: GetUrl('settings').url,
    name: GetUrl("settings").name, 
    meta: {title: 'settings', requires_auth: true},
    component: () => import(/* webpackChunkName: "settings" */ "@/views/user/control.vue"),
  },
  {
    path: GetUrl('users').url,
    name: GetUrl("users").name, 
    meta: {title: 'users', requires_auth: true},
    component: () => import(/* webpackChunkName: "users" */ "@/views/user/users.vue"),
  },
  {
      path: "/:pathMatch(.*)*",
      name: "dashboard", 
      meta: { title: 'Dashboard', requires_auth: true },
      component: () => import(/* webpackChunkName: "dashboard" */ "@/views/user/index.vue"),
  }
];