import { GetUrl } from "@/router/get-url.js";
import { UserLogin, UserLogout } from "@/services";
import router from "@/router";
import store from "@/store";
import { ResponseStatus } from "@/utils/";


export const UserLoginService = async (payload, remember) => { 
    let res = {
        success: false,
        msg: "error occured processing your request.",
        data: null
    };

    try {
        res = await UserLogin({payload});
        res = res.data;
        store.commit('Notify', {status: ResponseStatus(res.success), msg: res.msg});
        if(res.success)
        {
            if(remember) store.commit('RememberAuth', { 'user': this.user, 'password': this.password, 'remember': this.remember});
            store.commit('AuthUser', res.data);
            router.push(GetUrl('dashboard').url);
        }

    }
    catch (err) {
        store.commit('Notify', {status: 'error', msg: "error occured processing your request."});
    } 

    return;
}


export const UserLogoutService = async (payload) => { 

    let res = {
        success: false,
        msg: "error occured processing your request.",
        data: null
    };

    try {
        res = await UserLogout(payload);
        res = res.data;
        store.commit('Notify', {status: ResponseStatus(res.success), msg: res.msg});
    }
    catch (err) {
        store.commit('Notify', {status: 'error', msg: "error occured processing your request."});
    } 

    return res;
}
