<template> 
  <div :class="!this.url.includes('login') ? `mt-3` : null">  

      <!-- <TopNav v-if="!this.url.includes('login')" />  -->

      <Notification v-if="$store.state.notification.status"/>
      <div :class="!this.url.includes('login') ? `wrapper mt-0 pt-0` : null"> 
        <div :class="!this.url.includes('login') ? `mt-0 mb-50` : null">
          <router-view :key="$route.fullPath"></router-view>
        </div> 
      </div>
         
  </div>
</template>
<script>    
import store from "@/store";
import Notification from "@/components/notification.vue"; 
// import TopNav from "@/components/top_nav.vue"; 
import { GetUrl }  from "@/router/get-url.js";
export default {
name: "App", 
  data() {
    return{
      time_now: new Date(),
      url: window.location.href,
      url_path: window.location.pathname, // get url path
      url_query: window.location.search, // if there are any query on the url
      redirect: this.url_path + this.url_query,
      events: ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']
    }
  },   
  mounted() {    
    // setInterval(this.WATCH_TIMEOUT, SESSION_DURATION)
    // this.WATCH_SCRREN_TIME(); 
  },
  components: { 
    Notification
  },
  methods: {
      WATCH_TIMEOUT: function(){  

        if(this.time_now.getTime() > store.state.life_span && store.state.is_authenticated && !this.url.includes('auth')){
            store.commit('Notify', {status: "error", msg: "Your active session has expired due to inactivity."}); 
            window.location = GetUrl('session-expired').url+'?redirect='+this.redirect;
            // this.$router.push() 
        }   
        // clearInterval(); 
      },  
      WATCH_SCRREN_TIME: function(){ 
        
        window.addEventListener('load', resetTimer, true); 
        this.events.forEach(function (name) {
          document.addEventListener(name, resetTimer, true);
        });

        function resetTimer() { 
          let timeNow = new Date();
          if(timeNow.getTime() < store.state.life_span) store.commit('KEEP_ALIVE')
        }
    
      } 
  }, 
  beforeMount() {  
    this.url = window.location.href;
  }
};
</script> 
