import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";

import { GetUrl } from "@/router/get-url";
import { route } from "@/router/routes";
 

const routes = [...route]; //LOAD ALL ROUTES

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
 
router.beforeResolve((to, from, next) => {   
    NProgress.configure({ showSpinner: false }).start();
      
    if(to.meta.requires_auth && !store.state.is_authenticated) next(GetUrl('login').url); // IF REQUIRES AUTHENTICATION AND USER IS NOT AUTHENTICATED ** REDIRECT TO LOGIN PAGE
    
    else next();
  
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title; // paget title setter 
  next(); 
})

router.afterEach(() => { NProgress.done() });

export default router;