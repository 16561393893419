export const GetUrl = (dest) => { 
    let baseUrl = '/';
    switch (dest) {
        case 'login':
            return ({ url: baseUrl+'login', name: 'user login' });

        case 'session-expired':
            return ({ url: baseUrl+'session-expired', name: 'session expired' });
            
        case 'register':
            return ({ url: baseUrl+'register', name: 'register' });

        case 'reset-password':
            return ({ url: baseUrl+'reset-password', name: 'reset-password' });

        case 'forgot-password':
            return ({ url: baseUrl+'forgot-password', name: 'forgot-password' });

        case 'lock-account':
            return ({ url: baseUrl+'lock-account', name: 'lock-account' });

        case 'orders':
            return ({ url: baseUrl+'orders', name: 'orders' });

        case 'sales-and-Transactions':
            return ({ url: baseUrl+'orders/sales-and-Transactions', name: 'sales-and-Transactions' });

        case 'products':
            return ({ url: baseUrl+'products', name: 'products' });
                
        case 'raw-materials':
            return ({ url: baseUrl+'products/raw-materials', name: 'raw-materials' });

        case 'dashboard':
            return ({ url: baseUrl+'dashboard', name: 'dashboard' });

        case 'users':
            return ({ url: baseUrl+'users', name: 'users' });

        case 'activities':
            return ({ url: baseUrl+'activities', name: 'activities' });

        case 'settings':
            return ({ url: baseUrl+'settings', name: 'settings' }); 

        default:
            return ({ url: baseUrl, name: 'home' });
    }
}