import { createStore } from 'vuex'  
import createPersistedState from 'vuex-persistedstate'; 
import { CLIENT_OTP_DURATION, Encrypt, ParseAuth } from "@/utils/security/";  
import { UserLogoutService }  from "@/services/auth.js"; 
import { GetUrl }  from "@/router/get-url"; 
// import { IsEmpty } from "@/utils/";  
import router from "@/router";

const store = new createStore({

    state: {
        user: localStorage.getItem('user') || null,
        is_authenticated: localStorage.getItem('is_authenticated') || false,
        life_span: null, 
        countries: null,
        remember_user: null,
        temp_login_detail: '', 
        theme: 'light',  
        login_form: 'login',
        inventory: false,
        notification: {
            status: false, 
            payload: null
        },
    },

    mutations: {
         
        KEEP_ALIVE(state) {  
            state.life_span = CLIENT_OTP_DURATION()  
        }, 

        STORE_COUNTRIES(state, payload) {  
            state.countries = payload  
        },  
        
        SET_LOGIN_FORM_STATUS(state, payload) {  
            state.login_form = payload  
        },
 
        UserLogout(state) {  
            
            (async () =>{
                let id = ParseAuth(state.user).id
                let res = await UserLogoutService(Encrypt(id));
                if(res.success) { 
                    state.life_span = null;
                    state.user = null  
                    localStorage.setItem('auth', null)
                    localStorage.setItem('is_authenticated', false) 
                    state.is_authenticated = false;
                    router.push(GetUrl('login').url);// auth logout
                } 
            })();
   
        },

        AuthUser(state, payload) { 
            localStorage.setItem('user', payload)  
            state.user = payload
            state.is_authenticated = true
            localStorage.setItem('is_authenticated', true) 
        },

        RememberAuth(state, payload) { 
            state.remember_user = Encrypt(payload);
        },

        TEMP_LOGIN_DATA(state, payload){
            state.temp_login_detail = payload  
        },

        Notify(state, payload){
            state.notification.status = false;
            setTimeout(() => state.notification.payload = payload, 100) 
            setTimeout(() => state.notification.status = true, 100) 
            setTimeout(() => state.notification.status = false, 100) 
        }

    },

    actions: {},

    getters: {
        
        notify: state => state.notify,

        is_authenticated: state => state.is_authenticated,

        theme: state => state.theme, 
    },

    modules: {},

    plugins: [createPersistedState()],
});

store.subscribe((mutation, state) => { 
    localStorage.setItem('store', JSON.stringify(state));
});

export default store;