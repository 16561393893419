import axios from "axios";
import store from "@/store";
import { AppInfo } from "@/utils";
import { Encrypt, ParseAuth } from "@/utils/security";

export default (x) => {
  // let IP = `http://104.248.48.188`
  const IsLive = false;
  let IP = !IsLive ? `http://localhost` : `http://134.122.6.1`;
  let apiUrl = "";
  let header = false;
  let user = store.state.user;

  switch (
    x // switch to define perfect api route
  ) {
    case "auth":
      apiUrl = IP + `:1000/api/v1/auth/`; // if api request is for auth server
      header = false;
      break;

    case "user":
      apiUrl = IP + `:1000/api/v1/user/`; // if api request is for user server
      header = true;
      break;

    case "order":
      apiUrl = IP + `:1000/api/v1/order/`; // organization, subsidiary server routes
      header = true;
      break;

    case "product":
      apiUrl = IP + `:1000/api/v1/product/`;
      header = true;
      break;

    case "raw_material":
      apiUrl = IP + `:1000/api/v1/raw_material/`;
      header = true;
      break;

    case "utils":
      apiUrl = IP + `:1000/api/v1/utils/`;
      header = true;
      break;

    default:
      apiUrl = IP + `:1000/api/`;
  }

  if (header) {
    let Encrypted_header = Encrypt({
      // declare all header params
      token: ParseAuth(user).token,
    });
    return axios.create({
      // dispatch api route
      baseURL: apiUrl,
      headers: {
        authorization: Encrypted_header,
        api_key: Encrypt(AppInfo.api_key),
        "Access-Control-Allow-Origin": "*",
      },
    });
  } else {
    return axios.create({
      // dispatch api route
      baseURL: apiUrl,
      headers: {
        api_key: Encrypt(AppInfo.api_key),
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
};
