export const CHECK_ENV = () => {   
    const url = window.location.href;
    return url.includes('localhost:') || url.includes('127.0.0.1:') ? 'test' : 'live';
}

export const AppInfo = {
    ip: CHECK_ENV() === "test" ? "http://localhost" : "http://104.248.48.188", 
    baseUrl: CHECK_ENV() === "test" ? "http://localhost:8080" : "http://tasquik.com", 
    physical_address: '30 airport road Ikeja Lagos state.', 
    logo: 'https://res.cloudinary.com/tasquik/image/upload/v1640079991/assets/images/logo_cp4tsy.png',
    default_img: '/img/default.png',
    company: 'LegendOnyx',
    name: 'LegendOnyx',
    address: 'No2 Funsho Adium Close, Iju Lagos',
    phone: '07088999999',
    support: 'legendonyx22@gmail.com',
    admin_email: 'legendonyx22@gmail.com', 
    api_key: 'CURPdibl5MTRv89gHA3CrzwrGAdyAS1H29EdGAzPbUW4J9KP17',
}

export const WEEK_NUMBER = (date = new Date()) => {  
    let d = new Date(date);
    let startDate = new Date(d.getFullYear(), 0, 1);
    let days = Math.floor((d - startDate) / (24 * 60 * 60 * 1000));
          
    return Math.ceil(days / 7); 
}

export const CURRENT_WEEK_NUMBER = (date) => { 
    let _date = IsEmpty(date) ? new Date() : date;
    let startDate = new Date(_date.getFullYear(), 0, 1);
    let days = Math.floor((_date - startDate) / (24 * 60 * 60 * 1000));
          
    return Math.ceil((_date.getDay() + 1 + days) / 7); 
}

export const APP_CONSTANTS = {
    default_photo: '/img/default.png', 
}

export const GetCount = (x) => {
    return IsEmpty(x) ? 0 : Number(x.length); 
}

export const BOTTOM_VISIBLE = () => {
    var scrollY = window.scrollY
    var visible = document.documentElement.clientHeight
    var pageHeight = document.documentElement.scrollHeight
    var bottomOfPage = visible + scrollY >= pageHeight
    return bottomOfPage || pageHeight < visible
} 

export const INTERSECTION_OBSERVER = (xs) => {
 
    let config = {
        root: null, // avoiding 'root' or setting it to 'null' sets it to default value: viewport
        rootMargin: '0px',
        threshold: 0.5
    };
    let observer = new IntersectionObserver(function(entries) {
    entries.forEach(entry => {
        if (entry.isIntersecting) { 
            return true; 
        } else { 
            return false; 
        }
    });
    }, config); 

    xs.forEach(x => {
        observer.observe(x); 
    });
}

export const CALCU_YEARS = (y1, y2) => { 
    var dif = Number(y2) - Number(y1);
    return dif + ' year'+CheckPlural(dif);
}

export const CapString = (str) => {   
    var res = '';
    (str == null || str == undefined || str == '') ? res = '' : res = str[0].toUpperCase() + str.substring(1); 
    return res; 
} 

export const CHECK_VOWEL = (str) => {  
    let res = ['a','e','i','o','u'].indexOf(str[0].toLowerCase()) !== -1;
        return res ? `an ${str}` : `a ${str}` ;
}  

export const GENERATE_RAND_STRING = (length) => {
    
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let characterLength = characters.length;

    for(var i = 0; i < length; i++){
        result += characters.charAt(Math.floor(Math.random() * characterLength))+Date.now().toString(36);
    }

    return result
}
 

export const CheckPlural = (num) => {  
    return num > 1 ? 's' : '';
} 

export const GENERATE_RANDOM_NUM = (n) => { 
    var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   
    max        = Math.pow(10, n+add);
    var min    = max/10; // Math.pow(10, n) basically
    var number = Math.floor( Math.random() * (max - min + 1) ) + min;
    return ("" + number).substring(add); 
}  
 
export const ThousandSeparator = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
}

export const CalculatePercentage = (percent, price) => {
    var res = Number(percent) / 100 * Number(price);
    return `₦` + ThousandSeparator(res);
}

export const Getinitial = (str) => {
    if(str !== null){ 
        if (str.length > 1) {
            let x = str.slice(0, 2) + "";
            return x.toUpperCase();
        } else {
            return str.toUpperCase();
        }
    }
} 

export const TruncateString = (str, num) => {
    if(str !== null){ 
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }
}

export const ROUND_PLACE = (str, num) => { 
        if (str.length < num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        } 
}

export const TRUNCATE_BEFORE_CHARC = (str, char) => { 
    return str.substr(str.lastIndexOf(char) + 1);
} 
 
export const CHECK_BOOLEAN = (x) => { 
    return x === 't' ? true : false ;
}

export const CHECK_SELECTED = (id, lists) => { 
    for (const item of lists) {
        if(item == id){
            return true;
        } 
    } 
}

export const GET_HEAD_OFFICE = (array) => { 
    let lists = array.filter(x => x.head_office == 't')
    for (const item of lists) { 
        return item
    } 
} 

export const BOOLEN_VALUE = {
    TRUE: 1,
    FALSE: 0
} 

export const ResponseStatus = (x) => {
    return x ? 'success' : 'error';
}

export const PUNCTUATE = (total, num) => {
    if (total == num) {
        return ".";
    } else {
        return ", ";
    }
}

export const RATING = (num) => {
    if(num == 0){
    var info = `<span class="badge badge-danger">not rated</span>`; 
    return info;
    }
    else{
        var rating = [];
        for (var j = 0; j < num; j++) { 
            rating += `<i class="mdi mdi-star text-warning"></i>`;
        }  
        return rating;
   }
} 

export const HASH_NUMBER = 2580
 

export const RAND_STRING = (len) => { // CONVERT DAYS TO MILLI SECONDS 
    return Math.random().toString(36).substr(len);
}  

export const DAYS_IN_MILLISECONDS = (days) => { // CONVERT DAYS TO MILLI SECONDS
    const oneDayMS = 86400000; // milliseconds for a day  
    return Number(oneDayMS) * Number(days);
}

export const MILLISECONDS_TO_DAYS = (ms) => { // CONVERT DAYS TO MILLI SECONDS
    const oneDayMS = 86400000; // milliseconds for a day  
    return Number(ms) / Number(oneDayMS);
}
 
export const STRIP_STRING = (str) => {  
    return str.replace(/(<([^>]+)>)/gi, "");
}

export const RemoveCharacter = (str, charc) => {  
    return str.replace(charc, "");
}


export const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
    
export const MAX_DP_SIZE = 1024 * 512

export const THIRTY_DAYS_IN_MILLIS = 2592000000;

export const HOST = (req) => req.protocol + '://' + req.get('host')
 
export const FUTURE_DATE_MILI_SECS = (date, days) => date.setDate(date.getDate() + Number(days)); 
 
export const IsEmpty = (x) => { 

        let res = false;

        let type = typeof x;

        if (type === 'undefined') res = true; 

        if (x === '') res = true; 

        if (x === ' ') res = true; 

        if (x === null) res = true; 

        if (x === undefined) res = true; 

        if (x instanceof Array) {
            
            if (x.length < 1) res = true; 
        
            else if (type === 'string') {
                if (x.length < 1) res = true;
                if (x === '0') res = true; 
            } 
            else if (type === 'object') {
                if (Object.keys(x).length < 1) res = true; 
            } 
            else if (type === 'number') {
                if (x === 0) res = true; 
            }
        }

        return res;
}

export const NOTIFYER = (x) => {  
    let info = {name: AppInfo.name, image: AppInfo.logo}
    return (x === 'TQ')  ? info : false; 
}

  
export const StatusCode = { 
    ACTIVE: 1,
    INACTIVE: 2,
    NEW: 3,
    SEEN: 4,
    ONLINE: 5,
    OFFLINE: 6,
    OPEN: 7,
    CloseD: 8,
    CANCELED: 9,
    AVAILABLE: 10,
    OUT_OF_STOCK: 11,
    PAID: 12,
    PARTIAL: 13, 
}

export const FormatDate = (date) => {
    var d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export const StatusColor = (x) => {     
    let res = ''; 
    switch (x) {
        case StatusCode.ACTIVE :
            res = "primary"
        break; 
        
        
        case StatusCode.AVAILABLE :
            res = "primary"
        break; 

        case StatusCode.INACTIVATE:
         res = "dark"
        break;

        case StatusCode.APPROVED:
         res = "success"
        break;

        case StatusCode.OPEN:
            res = "warning"
        break;

        case StatusCode.OUT_OF_STOCK:
            res = "warning"
        break;

        case StatusCode.CANCELED:
            res = "danger"
        break; 

        case StatusCode.UNPROCESSED:
            res = "dark"
        break; 

        case StatusCode.ONLEAVE:
            res = "secondary"
        break; 

        case StatusCode.UNAPPROVE:
            res = "warning"
        break; 

        case StatusCode.CloseD:
            res = "success"
        break; 

        default:
            res = "dark"
        break;
    }   
    return res
} 


export const GET_COLOR = (x) => {      
    let color_arr = ["primary", "dark", "success", "warning", "danger", "secondary", "info"];
    return  x < 7 ? color_arr[x] : "dark";
} 
 
export const ReadableDate = (d) => {  
    const dd = new Date(`${d}`) 
    return dd.toDateString();
}
 
export const ExtractDate = (d, typ) => {  
    const dd = new Date(`${d}`) 
    if(typ == "year"){
        return dd.getFullYear();
    }
    
    if(typ == "month"){
        return dd.getMonth();
    }
    
} 

export const DAYS_REMAINING = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
 
 
export const ONBOARDING_STATUS = {
    completed: 99,
    bio: 1,
    education: 2,
    work_experience: 3,
    surity: 4,  
    employee: 5
 }

 export const ResponseType = {
     WILD_CARD: { code: 100, msg: 'special operation' },
     SUCCESS: { code: 200, msg: 'operation successfull' },
     CREATED: { code: 201, msg: 'resource was created' }, 
     ACCEPTED: { code: 202, msg: 'request was recived successfully' },
     EMPTY_CONTENT: { code: 204, msg: 'this request content is empty.' },
     BAD_REQUEST: { code: 400, msg: 'this request is not allowed.' }, 
     UNAUTHORIZED: { code: 401, msg: 'you are not authorized to perform this action.' },
     FORBIDDEN: { code: 403, msg: 'this action is forbidden.' },
     NOT_FOUND: { code: 404, msg: 'the resource requested was not found.' },
     METHOD_NOT_ALLOWED: { code: 405, msg: 'this request method is not allowed.' },
     RESOURCE_EXIST: { code: 406, msg: 'this resource already exists.' },
     PAYLOAD_TOO_LARGE: { code: 413, msg: 'request payload is too heavy.' },
     INTERNAL_SERVER_ERROR: { code: 500, msg: 'encountered an internal server error.' },
     BAD_GATEWAY: { code: 502, msg: 'bad gateway error encountered.' },
     SERVICE_UNAVAILABLE: { code: 503, msg: 'this service requested is currently unavailable.' },
     GATEWAY_TIMEOUT: { code: 504, msg: 'server timeout error occured.' },
 }

 export const CRUD_TYPE = {
    CREATE: { code: 1, success_msg: 'Operation was successfull', error_msg: 'Operation was unsuccessful.' }, 
    RETRIVE: { code: 2, success_msg: 'Record(s) requested was retrived successfully.', error_msg: 'Retrival operation was unsuccessful.' }, 
    UPDATE: { code: 3, success_msg: 'Update operation was successful.', error_msg: 'Update operation was unsuccessful.' },
    DELETE: { code: 4, success_msg: 'Delete operation was successful.', error_msg: 'Delete operation was unsuccessful.' }, 
 }
  
 export const RANDOM_STRING = (len) => {  
     let text = '';
     let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
 
     for( let i=0; i < len; i++ )
       text += possible.charAt(Math.floor(Math.random() * possible.length));
     return text;
 }
 
   
export const EvenNumber = (num) => { 
    return ( num % 2 == 0 ) ? true : false; 
}

export const RANDOM_INT = (len) => {  
    let num = '';
    let sample = '0123456789';

    for( let i=0; i < len; i++ )
      num += sample.charAt(Math.floor(Math.random() * sample.length));

    return num;
}

export const CheckEmailPattern = (email) => {  
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  
export const CHECK_IF_EMPTY = (v) => {  
          let type = typeof v;
          if (type === 'undefined') {
              return true;
          }
          if (type === 'boolean') {
              return !v;
          }
          if (v === null) {
              return true;
          }
          if (v === undefined) {
              return true;
          }
          if (v instanceof Array) {
              if (v.length < 1) {
                  return true;
              }
          } else if (type === 'string') {
              if (v.length < 1) {
                  return true;
              }
              if (v === '0') {
                  return true;
              }
          } else if (type === 'object') {
              if (Object.keys(v).length < 1) {
                  return true;
              }
          } else if (type === 'number') {
              if (v === 0) {
                  return true;
              }
          }
          return false;
}