import API from "./api.js";
export const UserLogout = (id) => API("auth").get("logout/"+id);
export const UserLogin = (data) => API("auth").post("login", data);

//user server routes
export const GetComments = (data) => API('user').get('load_all_comments/'+data);
export const SubmitComment = (data) => API('user').post('make_comment', data);
// export const UpdatePassword = (data) => API('user').post('update_user_password', data);
export const UploadUserPhoto = (ref, data) => API('user').post(`upload_photo/` + ref, data);
export const User2FA = (data) => API('user').post('update_user_2fa', data);
export const GetEvents = (id) => API('user').post('load_user_events/'+id);
export const ConfirmUser = (id) => API('user').post('confirm_user/'+id);
export const DelEvent = (id) => API('user').delete('delete_event/'+id);
export const UpdateEvent = (data) => API('user').post('update_event', data); 
export const GetEvents2 = (id) => API('user').get('load_events/'+id); 
export const AddEvent = (data) => API('user').post('add_event', data); 
export const GetTasks = (id) => API('user').post('load_tasks/'+id);
export const GetAllUsers = () => API('user').get('load_all_users'); 
export const GetAllUsersPaginated = (id) => API('user').get('load_all_users_paginate/'+id); 
export const RegisterUser = (data) => API('user').post('register_user', data); 
export const GetUser = (id) => API('user').get('load_user/'+id);
export const UpdateUser = (data) => API('user').post('update_user', data);
export const UpdateStaff = (data) => API('user').post('update_staff', data);
export const UpdatePassword = (data) => API('user').post('update_password', data); 
export const GetUserUtils = () => API('user').get('fetch_utils');
export const SearchUsers = (data) => API('user').get('search_users/'+data);
export const GetAllCustomers = () => API('user').get('load_customers');
export const GetAdmins = (page) => API('user').get('load_all_staffs_paginate/'+page);
export const GetActivities = (id) => API('user').get('load_all_activities/'+id) ;
export const GetUserActivities = (id) => API('user').get('load_all_user_activities/'+id) ;

//product server routes
export const GetRawMaterials = (id) => API('raw_material').get('load_raw_materials/'+id);
export const GetAllRawMaterials = () => API('raw_material').get('load_all_raw_materials');
export const GetRawMaterial = (id) => API('raw_material').get('load_raw_material/'+id);
export const GetRawMaterialInventory = (id) => API('raw_material').get('load_raw_material_inventory/'+id);
export const GetRawMaterialInventoryRecords = (id) => API('raw_material').get('load_raw_material_inventory_records/'+id);
export const GetRawMaterialInventoryDate = (id) => API('raw_material').get('load_raw_material_inventory_date/'+id);
export const GetInventory = () => API('raw_material').get('load_inventory');
export const GetInventoryRecords = (id) => API('raw_material').get('load_inventory_records/'+id);
export const GetRawMaterialSales = (id) => API('raw_material').get('load_raw_material_sales/'+id);
export const GetRawMaterialSalesSumarry = (id) => API('raw_material').get('load_raw_material_sales_summary/'+id);
export const RestockRawMaterial = (data) => API('raw_material').post('restock_raw_material', data);
export const UpdateRawMaterial = (data) => API('raw_material').post('update_raw_material', data);
export const UpdateCategory = (data) => API('raw_material').post('update_category', data);
export const AddCategory = (data) => API('raw_material').post('add_category', data);
export const DelCategory = (id) => API('raw_material').post('del_category/'+id);
export const AddRawMaterial = (data) => API('raw_material').post('add_raw_material', data);
export const UpdateDiscount = (data) => API('raw_material').post('update_discount', data);
export const AddDiscount = (data) => API('raw_material').post('add_discount', data);
export const DelDiscount = (id) => API('raw_material').delete('del_discount/'+id);
export const SearchRawMaterials = (keyword, page) => API('raw_material').get('search_raw_material/'+keyword+'/'+page);
export const GetProducts = (page) => API('product').get('load_products/'+page);
export const GetAllProducts = () => API('product').get('load_all_products');
export const GetProduct = (id) => API('product').get('load_product/'+id);
export const GetProductsInventory = () => API('product').get('load_inventory');
export const GetProductInventory = (id) => API('product').get('load_product_inventory/'+id);
export const GetProductInventoryRecords = (data) => API('product').get('load_inventory_records/'+data);

export const GetProductInventoryDate = (id) => API('product').get('load_product_inventory_date/'+id);
export const TakeInventory = () => API('product').get('take_inventory');
export const GetAProductInventoryRecords = (data) => API('product').get('load_product_inventory_records/'+data);
export const GetProductsales = (data) => API('product').get('load_product_sales/'+data);
export const GetProductsalesSummary = (id) => API('product').get('load_product_sales_summary/'+id);
export const RestockProduct = (data) => API('product').post('restock_product', data);
export const UpdateProduct = (data) => API('product').post('update_product', data);
export const UpdateProductCategory = (data) => API('product').post('update_category', data);
export const AddProductCategory = (data) => API('product').post('add_category', data);
export const DelProductCategory = (id) => API('product').delete('category/'+id);
export const AddProduct = (data) => API('product').post('add_product', data);
export const UpdateProductDiscount = (data) => API('product').post('update_discount', data);
export const AddProductDiscount = (data) => API('product').post('add_discount', data);
export const DelProductDiscount = (id) => API('product').get('del_discount/'+id);
export const UpdateMeasurment = (data) => API('product').post('update_measurment', data);
export const AddMeasurment = (data) => API('product').post('add_measurement', data);
export const DelMeasurment = (data) => API('product').delete('measurement/'+data);
export const SearchProduct = (keyword, page) => API('product').get('search_product/'+keyword+'/'+page);

//Order SERVER SERVICES
export const GetInvoice = (data) => API('order').get('load_invoice/'+data);
export const GetOrderSummary = (id) => API('order').get('load_order_summary/'+id);
export const GetAdminOrderSummary = (id) => API('order').get('load_order_summary_admin/'+id);
export const GetOrders = (data) => API('order').get('load_orders/'+data);
export const GetCustomerSales = (id) => API('order').get('load_customer_sales/'+id);
export const GetStaffOrders = (payload) => API('order').get('load_staff_orders/'+payload);
export const GetCustomerOrders = (payload) => API('order').get('load_customer_orders/'+payload);
export const PlaceOrder = (data) => API('order').post('place_order', data);
export const SendInvoiceMail = (data) => API('order').get('send_invoice_mail/'+data);
export const GetSales = (data) => API('order').get('load_sales/'+data);
export const GetOrdersSummary = (id) => API('order').get('load_orders_summary/'+id);
export const UpdateOrderStatus = (data) => API('order').post('update_status', data);
export const GetSalesSummary = () => API('order').get('load_sales_summary');
export const GetStaffSalesSummary = (id) => API('order').get('load_users_sales_summary/'+id);
export const GetUserSalesSummary = (id) => API('order').get('load_user_sales_summary/'+id);
export const SearchOrders = (data) => API('order').get('search_order/'+data);

//Utils server routes
export const GetUtils = (data) => API('utils').get('load_utils/'+data);
export const GetCustomerLocations = (id) => API('utils').get('load_customer_locations/'+id);
export const GetCities = (id) => API('utils').get('cities/'+id);
export const GetStates = (id) => API('utils').get('states/'+id);
export const GetCountries =() =>  API('utils').get('countries');